<template>
  <div class="news-card" @click="gotoNews(news)">
    <div class="news">
      <div class="news__headline secondary-heading">
        {{ news.title }}
      </div>

      <div class="news__detail" v-html="news.content"></div>

      <div class="news__footer">
        <div class="date">
          <icon-base class="mr-1">
            <icon-calendar />
          </icon-base>
          {{ news.published_at }}
        </div>

        <div class="editor">
          <icon-base class="mr-1">
            <icon-edit />
          </icon-base>
          {{ news.editor }}
        </div>
      </div>
    </div>

    <div v-if="news.news_images.length > 0" class="news-card__image">
      <img :src="news.news_images[0].image_url" :alt="news.title" />
    </div>
  </div>
</template>

<script>
  import IconBase from "@/components/IconBase.vue";
  import IconCalendar from "@/components/icons/IconCalendar.vue";
  import IconEdit from "@/components/icons/IconEdit.vue";

  export default {
    name: "NewsCard",

    props: {
      news: { type: Object, required: true },
    },

    components: {
      IconBase,
      IconCalendar,
      IconEdit,
    },

    methods: {
      gotoNews(news) {
        this.$router.push({
          name: "News Detail",
          params: { newsId: news.id, title: news.title },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .news-card {
    display: flex;
    justify-content: space-around;

    padding: 2.5rem 0;
    cursor: pointer;

    &__image {
      flex-shrink: 0;

      width: 300px;
      height: 220px;
      margin-left: 1.5rem;

      background: $background-color;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;

        transform: scale(1.15);
        transition: transform 200ms linear;
      }
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      .news-card__image {
        margin-left: 0;
        margin-right: 1.5rem;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid lightgray;
    }

    &:hover &__image > img {
      transform: scale(1);
    }

    &:hover .news__footer svg {
      color: black;
    }
  }

  .news {
    flex-grow: 1;

    display: flex;
    flex-direction: column;

    padding: 0.5rem 0;

    &__headline {
      @include line-clamp(2);
    }

    &__detail {
      margin-bottom: 1rem;
      @include line-clamp(4);
    }

    &__footer {
      display: flex;
      align-items: center;
      margin-top: auto;
      color: gray;

      & > * {
        margin-right: 2rem;
      }

      .date,
      .editor {
        display: flex;
        align-items: center;

        svg {
          flex-shrink: 0;
          margin-right: 0.5rem;
          margin-bottom: 2px;
          transition: color 200ms linear;
        }
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .news-card {
      flex-direction: column-reverse;

      &__image {
        width: 100%;
        margin-bottom: 1rem;
        margin-left: 0;
      }

      &:nth-child(odd) {
        flex-direction: column-reverse;
        margin-right: 0;
      }
    }

    .news__footer {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        margin-bottom: 0.5rem;
      }
    }
  }
</style>
