<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />

    <div class="container default-padding">
      <div class="section-padding">
        <div class="primary-heading text-center">
          {{ labels.PH_NEWS_UPDATE }}
        </div>

        <div class="caption mb-8">{{ labels.PH_NEWS_QUOTE }}</div>

        <el-row v-if="!loading">
          <el-col v-if="newsList.length > 0" :span="24">
            <news-card
              v-for="news in newsList"
              :key="news.id"
              :news="news"
              data-aos="fade-up"
            />

            <el-row v-if="showLoadMore" type="flex" justify="center">
              <div class="btn-text" @click="loadMore">
                {{ labels.LBL_LOAD_MORE }}
              </div>
            </el-row>
          </el-col>

          <div v-else class="text-center">{{ labels.PH_NEWS_NF }}</div>
        </el-row>

        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import BreadCrumb from "@/components/BreadCrumb.vue";
  import NewsCard from "@/components/NewsCard.vue";

  export default {
    name: "News",

    components: {
      BreadCrumb,
      NewsCard,
    },

    data() {
      return {
        labels,

        breadcrumbsItems: [{ name: "News", path: "" }],

        newsList: [],

        params: {
          page: 1,
          limit: 5,
          published: 1,
        },
        lastPage: 0,
      };
    },

    computed: {
      ...mapState({ loading: (state) => state.isBusy }),

      showLoadMore() {
        return this.params.page < this.lastPage;
      },
    },

    watch: {
      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadNews();
        },
      },
    },

    methods: {
      async loadNews() {
        const {
          data,
          meta: { last_page: lp },
        } = await this.$store.dispatch("news/getAllNews", this.params);
        this.newsList = [...this.newsList, ...data];
        this.lastPage = lp;
      },

      loadMore() {
        this.params.page += 1;
      },
    },
  };
</script>
